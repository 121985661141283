import React, {useContext, useState} from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../services/auth.service'

function Landing(){
    // const {x} = useContext(GlobalContext);
    // const [user, setUser] = x;
    // const {y} = useContext(GlobalContext);
    // const [authenticated, setAuthenticated] = y;
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [credentialError, setCredentialError] = useState("");
    const history = useHistory();
    const loginHandler = (e) =>{
      e.preventDefault();
      const email = document.getElementById('email').value;
      const password = document.getElementById('password').value
      if(email !== "" && password !==""){
        // console.log(email, password);
        // axios.post('/api/users/login', {email:email, password:password})
        // .then(res => {
        //   const {token} = res.data;
        //   localStorage.setItem('jwtToken', token);
        //   // setAuthToken(token);
        //   const decoded = jwt_decode(token);
        //   // setUser(decoded);
        //   // setAuthenticated(true);
          
        //   history.push('/DataPage');
        // })
        // .catch((err) => {
        //   console.log(err);
        // })
       AuthService.login(email, password).then(
         (res) => {
           if (!res) {
             setCredentialError("Invalid Credentials! Try Again.");
             return;
           }
           history.push('/DataPage');
           
         })
         .catch((err)=> {
           console.log(err, 'here');

         })
       
      }else{
        if(email === ""){
          setUsernameError("Please fill this field");
        }
        if(password === ""){
          setPasswordError("Please fill this field");

        }
      }
      
    }
    return (
        <div className="container">

        <h1> Login </h1>
        {credentialError && <div class="alert alert-danger" role="alert">
          {credentialError}
        </div>}
        <form>
        <div className="form-group">
          <label for="email">Username</label>
          <input type="email" className="form-control" id="email" placeholder="Enter Username" />
          {usernameError && <div class="alert alert-danger" role="alert">
            {usernameError}
          </div>}

        </div>
        <div className="form-group">
          <label for="password">Password</label>
          <input type="password" className="form-control" id="password" placeholder="Password" />
          {passwordError && <div class="alert alert-danger" role="alert">
            {passwordError}
          </div>}

        </div>
              {/* <Link to="/DataPage" > */}

        <button  className="btn btn-primary" onClick={loginHandler}>Login</button>
              {/* </Link> */}
      </form>
      </div>
    )
}
export default Landing;
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/data/';

class UserService {
  getData() {
    return axios.get(API_URL,{headers: authHeader()} );
  }

  getNewData(){
      return axios.get(API_URL+ 'new/gen', {headers: authHeader()} )
  }

  deleteData(id){
      return axios.delete(API_URL + id, {headers: authHeader()} );
  }

  getDatabyId(id){
      return axios.get(API_URL + id, {headers: authHeader()});
  }

 updateData(data){
     return axios.post(API_URL, data, {headers: authHeader()});
 }
 updateDatabyID(data, id){
     return axios.post(API_URL +id, data, {headers: authHeader()})
 }
}

export default new UserService();

import React, {createContext, useState} from "react";

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
    const [user, setUser] = useState({});
    const [authenticated, setAuthenticated] = useState(false);

    return (
        <GlobalContext.Provider value={{ x: [user, setUser], y:[authenticated, setAuthenticated]}}>
            {props.children}
        </GlobalContext.Provider>
    )
}
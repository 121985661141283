import React from "react";
import "./assets/styles/App.css";
import {BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import LandingPage from './components/LandingPage'
import DataPage from './components/DataPage'
import AddData from './components/AddData'
function App(){
  return(
    <Router>
      <Switch>
          
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/DataPage" component={DataPage } />
        <Route exact path="/addData" component={AddData } />
        
      </Switch>
    </Router>
  )
}


export default App;
import React, { Component, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../GlobalContext";
import FontAwesome from "react-fontawesome";
import axios from "axios";
import ReactPaginate from 'react-paginate';
import authService from "../services/auth.service";
import userService from "../services/user.service";
import logo from '../assets/images/Website_Logo.png';
import "../assets/styles/styles.css";

class DataPage extends Component {
  // const {x}= useContext(GlobalContext);
  // const [loadedData, setloadedData] = useState([]);

  // useEffect (async() => {

  //     // console.log(, 'res');
  //     await loadData();

  // },[])
  constructor(props) {
    super(props);
    this.state = {
      searchData: "",
      data: [],
      currentUser: authService.getCurrentUser(),
      page: 0,
      offset: 0,
      currentPageData: [],
      currentPage: 0,
      pageCount: 1,
      PER_PAGE: 10,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleResetData = this.handleResetData.bind(this);
  }
  async removeHandler(e, id) {
    console.log(e, "hello", id);
    console.log(e);
    // const {data}= await axios.delete(`/api/data/${e}`)
    // console.log(data);
    userService.deleteData(e).then(() => {
      this.componentDidMount();
    });
  }
  componentDidMount() {


    if (this.state.currentUser) {
      this.loadData();

    } else {
      this.props.history.push("/");
    }
  }
  logoutHandler = (e) => {
    this.props.history.push('/')
  }
  // logoutHandler() {
  //   authService.logout();
  //   window.location.reload();
  // }
  downloadCSV(csv, filename) { }

  exportTableToCSV() {
    var csv = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      console.log(cols);
      for (var j = 0; j < 6; j++) row.push(cols[j].innerText);
      csv.push(row.join(","));
    }
    var csv = csv.join("\n");
    var filename = "data.csv";
    var csvFile;
    var downloadLink;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  loadData = async () => {
    // const {data} = await axios.get('/api/data');
    // console.log(data, 'vale');
    // for(let i of data){
    //     console.log(i);
    //     setField((field) => [...field, i]);
    // }
    await userService.getData().then((data) => {
      this.setState({ data: data.data });
    });
    const offset = this.state.currentPage * this.state.PER_PAGE;
    const pageCount = Math.ceil(this.state.data.length / this.state.PER_PAGE);
    this.setState({ offset: offset });
    this.setState({ pageCount: pageCount })
  };

  handlePageClick({ selected: selectedPage }) {
    console.log(this.state, 'lol');
    const offset = selectedPage * this.state.PER_PAGE;
    const pageCount = Math.ceil(this.state.data.length / this.state.PER_PAGE);
    this.setState({ offset: offset });
    this.setState({ pageCount: pageCount })
  }

  handleOnChange(e) {
    const updatedSearchData = e.target.value;
    this.setState({ searchData: updatedSearchData });
  }

  async handleOnClick() {
    if (this.state.searchData.length == 6 || this.state.searchData == '') {
      await userService.getDatabyId(this.state.searchData).then((data) => {
        this.setState({ data: data.data });
      });
      const offset = this.state.currentPage * this.state.PER_PAGE;
      const pageCount = Math.ceil(this.state.data.length / this.state.PER_PAGE);
      this.setState({ offset: offset });
      this.setState({ pageCount: pageCount })
    } else {
    }
  }

  handleResetData() {
    window.location.reload();
  }

  render() {
    return (
      <div className="container">
        <div className="row mt-4 mb-4">
          <div className="col">
            <img src={logo} style={{ position: 'absolute', top: this.props.top, left: this.props.left }} />

            <div style={{ float: 'right' }}>
              <input
                type="text"
                class="mr-2"
                id="serach"
                placeholder="Search Device ID"
                value={this.state.searchData}
                onChange={(e) => { this.handleOnChange(e) }}
                required
              />
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={this.handleOnClick}
              >
                Search
              </button>
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={this.handleResetData}
              >
                Reload Page
              </button>
              <Link to="/addData">
                <button className="btn btn-info mr-2">Add Device</button>
              </Link>
              <button
                className="btn btn-success mr-2"
                onClick={this.exportTableToCSV}
              >
                Download
              </button>
              <button
                type="submit"
                className="btn btn-warning mr-2"
                onClick={e => this.logoutHandler(e)}
              >
                LogOut
              </button>
            </div>
          </div>
        </div>
        <div className="table-responsive-sm">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Device ID</th>
                <th scope="col">IMEI Number</th>
                <th scope="col">User Name</th>
                <th scope="col">Password</th>
                <th scope="col">Sim Name</th>
                <th scope="col">Sim Number</th>
                <th scope="col">Edit</th>
                {/*     <th scope="col">Remove</th>*/}
              </tr>
            </thead>

            <tbody>
              {/* <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
      <td>  <button class='btn btn-success'> Edit</button> </td>
      <td>  <button class='btn btn-danger'> Remove</button> </td>
    </tr> */}
              {this.state.data.slice(this.state.offset, this.state.offset + this.state.PER_PAGE).map((arr, index) => (
                <tr key={arr._id}>
                  <th scope="row">{arr.id}</th>
                  <td>{arr.emi}</td>
                  <td>{arr.username}</td>
                  <td>{arr.uepassword}</td>
                  <td>{arr.simname}</td>
                  <td>{arr.simnumber}</td>
                  <td>
                    {" "}
                    <Link to={{ pathname: "/addData", state: { id: arr._id } }}>
                      {" "}
                      <button class="btn btn-success btn-sm"> Edit</button>
                    </Link>{" "}
                  </td>
                  {/*     <td>
                  {" "}
                    <button
                     class="btn btn-danger btn-sm"
                     onClick={this.removeHandler.bind(this, arr._id)}
                   >
                     {" "}
                      Remove
                    </button>{" "}
                  </td>*/}
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination justify-content-center"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          </div>

        </div>
      </div>
    );
  }
}
export default DataPage;

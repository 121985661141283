import axios from "axios";


class AuthService {
  login(username, password) {
    return axios
      .post("/api/users/login", {
        email:username,
        password:password
      })
      .then(response => {
      
          console.log(response.data, 'responser');
        if (response.data.token) {
            
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return true;
      }).catch((err) => {
        console.log("hello", err)
        return false;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();

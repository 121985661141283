import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from '../GlobalContext';
import axios from "axios";
import userService from '../services/user.service';
import authService from '../services/auth.service'
const createHistory = require("history").createBrowserHistory;
function AddData(props) {
    const simData = ["Airtel", "Hologram", "Safaricom", "Other"];
    const variantData = ["Pro/Plus", "Promax"];
    const [variantHidden, setVariantHidden] = useState(false);
    const history = useHistory();
    const [id, setId] = useState("");
    const [emi, setEMI] = useState("");
    const [selectedSimName, setSelectedSimName] = useState("");
    const [selectedVariant, setSelectedVariant] = useState("");
    const [simnumber, setSimnumber] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [dataError, setdataError] = useState("");
    const [idError, setIdError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [emiError, setEMIError] = useState("");
    const user = localStorage.getItem('user');


    useEffect(async () => {
        // console.log(props.location.state.id);
        if (!user) {
            history.push('/');
        }
        if (props.location.state && props.location.state.id) {
            console.log("hii");
            const { data } = await userService.getDatabyId(props.location.state.id);
            // const {data} = await axios.get(`/api/data/${props.location.state.id}`);
            setId(data.id);
            setEMI(data.emi);
            setSelectedSimName(data.simname);
            setSimnumber(data.simnumber);
            setPassword(data.uepassword);
            setUsername(data.username);
            setVariantHidden(true);
        } else {
            console.log("Hello");
            const { data } = await userService.getNewData();
            setPassword(data.password);
            setId(data.id);
            setUsername(data.id);
            setVariantHidden(false);

        }
    }, [user]);
    //  const logoutUser = ()  => {
    //     localStorage.removeItem("jwtToken");
    //     setAuthToken(false);
    //     dispatch(setCurrentUser({}));
    //   };
    const submitHandler = async (e) => {
        e.preventDefault();
        String.prototype.isNumber = function () { return /^\d+$/.test(this); }
        console.log(id, emi, username, password);
        if (props.location.state && props.location.state.id) {
            if ((id === "") || emi === "" || username === "" || password === "" || id.length !== 6 || emi.length !== 15 || password.length !== 8 || id !== username || (!emi.isNumber())) {
                if (id === "") {
                    setIdError("Fill in the details");
                }
                if (emi === "") {
                    setIdError("Fill in the details");
                }
                if (username === "") {
                    setIdError("Fill in the details");
                }
                if (password === "") {
                    setIdError("Fill in the details");
                }
                if (id.length !== 6) {
                    setIdError("Device Id Length must be of 6 digits");
                }
                if (emi.length !== 15) {
                    setEMIError("IMEI should be of 15 digits");
                }
                if (password.length !== 8) {
                    setPasswordError("Password should be of 8 digits");
                }
                if (id !== username) {
                    setUsernameError("ID and Username should be same");
                }
                if (!emi.isNumber()) {
                    setEMIError("IMEI should be numeric only");
                }
                console.log('No data');
            } else {
                var details = { id: id, emi: emi, simname: selectedSimName, simnumber: simnumber, username: username, password: password };

                const data = await userService.updateDatabyID(details, props.location.state.id)
                // const {data} = await axios.post(`/api/data/${props.location.state.id}`,{id:id, emi:emi, username:username, password:password})
                // console.log(data, "inserted");
                console.log(data, 'whta messge')
                if (data.data.message) {
                    console.log(data.data);
                    setdataError(data.data.message)
                } else {
                    window.alert(` Added Successfully`);
                    history.push('/DataPage')
                }
            }
        } else {

            if ((id === "") || emi === "" || username === "" || password === "" || id.length !== 6 || emi.length !== 15 || password.length !== 8 || id !== username || (!emi.isNumber())) {
                if (id === "") {
                    setIdError("Fill in the details");
                }
                if (emi === "") {
                    setEMIError("Fill in the details");
                }
                if (username === "") {
                    setUsernameError("Fill in the details");
                }
                if (password === "") {
                    setPasswordError("Fill in the details");
                }
                if (id.length !== 6) {
                    setIdError("Device Id Length must be of 6 digits");
                }
                if (emi.length !== 15) {
                    setEMIError("IMEI Length must be of 15 digits");
                }
                if (password.length !== 8) {
                    setPasswordError("Password Length must be of 8 characters");
                }
                if (id !== username) {
                    setUsernameError("ID and Username should be same");
                }
                const check = /^[0-9]+$/;
                if (!emi.isNumber()) {
                    setEMIError("IMEI should be numeric only");
                }
                console.log('No data', passwordError);
            } else {
                // const {data} = await axios.post('/api/data',{id:id, emi:emi, username:username, password:password})
                // console.log(data, "inserted");
                var details = { id: id, emi: emi, username: username, simname: selectedSimName, simnumber: simnumber, password: password, variant: selectedVariant };
                var msg = await userService.updateData(details);
                console.log(msg, 'whta messge')
                if (msg.data.message) {
                    console.log(msg.data);
                    setdataError(msg.data.message)
                } else {
                    window.alert(` Added Successfully`);
                    history.push('/DataPage')
                }
            }
        }

    }
    function logoutHandler() {
        authService.logout();
        history.push('/');
    }
    const resetHandler = (e) => {
        e.preventDefault();
        setEMI("");
        setId("");
        setSimnumber("");
        setPassword("");
        setUsername("");
    }
    return (
        <div className="container mt-4">
            <div className='row mb-4'>              <Link to='/DataPage'>

                <button className="btn btn-info mr-3">View Data</button>

            </Link>          <button className="btn btn-warning mr-3" onClick={logoutHandler}>LogOut</button>
            </div>
            <h1> Add Data </h1>
            <form>
                <div class="form-group">
                    <label for="id">Device ID</label>
                    <input type="text" class="form-control" maxLength="6" id="id" placeholder="Enter Device ID" value={id} onChange={(e) => {
                        e.preventDefault();
                        setId(e.target.value);
                    }} required />
                    {idError && <div className='alert alert-danger'>{idError} </div>}
                </div>
                <div class="form-group">
                    <label for="emi">IMEI</label>
                    <input type="text" class="form-control" id="emi" maxLength="15" placeholder="Enter IMEI Number" value={emi} onChange={(e) => {
                        setEMI(e.target.value);
                    }} required />
                    {emiError && <div className='alert alert-danger'>{emiError} </div>}
                </div>
                <div class="form-group " hidden={variantHidden}>
                    <label for="id">Variant</label>
                    <select className="form-control" id="variantList" onChange={(e) => {
                        setSelectedVariant(e.target.value);
                    }} value={selectedVariant}>
                        <option value="">Select a Variant</option>
                        {variantData.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div class="form-group">
                    <label for="id">Sim Name</label>
                    <select className="form-control" id="simname" onChange={(e) => {
                        setSelectedSimName(e.target.value);
                    }} value={selectedSimName}>
                        <option value="">Select a Sim Name</option>
                        {simData.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div class="form-group">
                    <label for="id">Sim Number</label>
                    <input type="text" class="form-control" maxLength="20" minLength="20" id="simnumber" placeholder="Enter Sim Number" value={simnumber} onChange={(e) => {
                        setSimnumber(e.target.value);
                    }} />
                </div>
                <div class="form-group">
                    <label for="username">UserName</label>
                    <input type="text" class="form-control" id="username" maxLength="6" value={username} placeholder="Enter Username" onChange={(e) => {
                        setUsername(e.target.value);
                    }} required />
                    {usernameError && <div className='alert alert-danger'>{usernameError} </div>}
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="text" class="form-control" maxLength="8" id="password" value={password} placeholder="Enter Password" onChange={(e) => {
                        setPassword(e.target.value);
                    }} required />
                    {passwordError && <div className='alert alert-danger'>{passwordError} </div>}
                </div>
                {dataError && <div className='alert alert-danger'>{dataError} </div>}
                <button type="submit" class="btn btn-info mr-3" onClick={submitHandler}>Submit</button>
                <button class="btn btn-danger mr-3" onClick={resetHandler}>Reset</button>

            </form>
        </div>
    )
}
export default AddData;